<template lang="pug">
  div#templateColsContainer.px-1.pt-1(style="height: 100%; overflow: hidden;")
    .justify-content-center.h-100(v-if='isLoadingCreditProvider || isUpdatingCreditProvider')
      .content-loader-center.m-0.h-100
        .text-center.flex-center
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            | {{ isLoadingCreditProvider ? 'Chargement du formulaire...' : 'Enregistrement en cours...' }}
    div.h-100(v-else :key="key")
      .d-flex.w-100.content-scrollable-sticky.m-0.h-100.justify-content-center
        div.h-100.col-6.content-scrollable-sticky-main.cell-right
          iframe.pdfViewver(:src="creditProvider.path+'#navpanes=0' " style="width: 100%; height: 100%; border: solid 2px #f1f1f1; padding:0px") 
        .d-flex.flex-column.content-scrollable-sticky-main.cell-right.p-0.w-50
          validation-observer.p-1.d-flex.flex-column.w-100(ref='formCreditProvider' #default='{ errors }' style="height: 100%")
            .d-flex.align-items-center.justify-content-between.px-1(style="margin-bottom: 1rem !important")
              .d-flex.align-items-center
                h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2;")
                  | {{ $route.meta.pageTitle }}
                h3.m-0 {{ creditProvider.documentReference }}
                div.cursor-pointer.text-primary(title="Ouvrir le pdf dans un nouvel onglet" @click="openInNewTab(creditProvider.path+'#navpanes=0')")
                  feather-icon.ml-1(icon='ExternalLinkIcon' size='20' @click="openInNewTab(creditProvider.path+'#navpanes=0')")
              .content-header-actions.d-flex
                feather-icon.cursor-pointer(icon='XIcon' size='24' @click='cancel()')
            div.m-0.justify-content-center
              b-col#nav-sticky-horizontal.w-100(style='padding: 0 !important')
                ul.d-flex.border-bottom-grey-light
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.w-100.pt-0(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                      span Détails de l'avoir fournisseur
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.w-100.pt-0(:class="tabActive == 'repartitions' ? 'current' : ''" @click="changeTab('repartitions'), setHeaderData()")
                      span Répartition
            #details.d-flex.flex-column.pt-1(v-if="tabActive == 'details'" style="height: 100%; overflow-y: auto;")
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.mb-1.w-100.px-1
                  b-form-group(label="Numéro de l'avoir *")
                    validation-provider(#default='{ errors }' name='documentReference' rules="required")
                      ejs-textbox(id="documentReference" :disabled="readOnly" v-model="creditProvider.documentReference" @change="checkUniqueProviderAndNumber" placeholder="Numéro de l'avoir")
                      small.text-danger {{ errors[0] }}
                      small.text-danger(v-if="isDuplicateProviderAndNumber") Ce numéro d'avoir existe déjà pour ce fournisseur.
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.mb-1.w-100.px-1
                  b-form-group(label="Date du document *")
                    validation-provider(#default='{ errors }' name='documentDate' rules="required")
                      ejs-datepicker(@change="creditProvider.valueDate = creditProvider.documentDate" :disabled="readOnly" :format="'dd/MM/yyyy'" locale='fr' v-model="creditProvider.documentDate" placeholder="JJ/MM/AAAA")
                      small.text-danger {{ errors[0] }}
                .d-flex.mb-1.w-100.px-1
                  b-form-group(label="Date de valeur *")
                    validation-provider(#default='{ errors }' name='valueDate' rules="required")
                      ejs-datepicker(:format="'dd/MM/yyyy'" :disabled="readOnly" locale='fr' v-model="creditProvider.valueDate" placeholder="JJ/MM/AAAA")
                      small.text-danger {{ errors[0] }}
                      .d-flex.my-0.w-100( style="padding: 0 !important;")
              .d-flex.mb-1.w-100.px-1
                b-form-group(label="Description")
                  validation-provider(#default='{ errors }' name='description')
                    quill-editor.quill-editor-scroll(class="editor editor-notes" :disabled="readOnly" v-model="creditProvider.description" :options="editorOption")
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.mt-1.w-100.px-1
                  h3.text-primary Montants
              .d-flex.align-items-end(v-for="(item, index) in creditProvider.referentielTvas" :key="index")
                div(style="flex:1")
                  .d-flex.my-0.w-100.align-items-start( style="padding: 0 !important;")
                    .d-flex.mb-1.w-100.px-1
                      b-form-group(:label="index == 0 ? 'Total HT *' : ''")
                        validation-provider(#default='{ errors }' :name="'totalHT-'+index" rules="required")
                          ejs-numerictextbox(@blur="calculateTotals(true)" :disabled="readOnly" :id="'totalHT-'+index" format="########.## €" v-model="item.value")
                          small.text-danger {{ errors[0] }}
                    .d-flex.mb-1.w-100.px-1
                      b-form-group(:label="index == 0 ? 'Taux de TVA *' : ''")
                        validation-provider(#default='{ errors }' :name="'referentielTvaId-'+index" rules="required" mode='lazy')
                          ejs-dropdownlist(@change="calculateTva(item, index), generateTvasList()" @created="generateTvasList()" :enabled="!readOnly" :id="'referentielTvasreferentielTvaId-'+index" popupHeight='300px' :dataSource='tvasList[index]' :fields="{ text: 'label', value: 'id' }" v-model='item.referentielTvaId')
                          small.text-danger {{ errors[0] }}
                    .d-flex.mb-1.w-100.px-1
                      b-form-group(:label="index == 0 ? 'Total TVA *' : ''")
                        validation-provider(#default='{ errors }' :name="'totalTVA-'+index" rules="required")
                          ejs-numerictextbox(@blur="calculateTotals" :disabled="readOnly" :id="'totalTVA-'+index" format="########.## €" v-model="item.valueTva")
                          small.text-danger {{ errors[0] }}
                    .d-flex.px-1.align-items-end.h-100(v-if="!readOnly" :class="index == 0 ? 'container-trash' : ''")
                        b-button.d-flex.align-items-center( variant="outline-danger" style="height: 30px;" @click="deleteReferencielTva(item, index) ") 
                          feather-icon.m-0(icon="TrashIcon")
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.mb-1.w-100.px-1
                  small.cursor-pointer.text-primary(v-if="!readOnly" @click="addReferencielTva") + Ajouter une ligne de TVA
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.mb-1.w-100.px-1
                  b-form-group(label="Total TTC *")
                    validation-provider(#default='{ errors }' name='totalTTC')
                      ejs-numerictextbox(disabled id="totalTTC" format="########.## €" v-model="creditProvider.totalTTC")
                      .d-flex.my-0.w-100( style="padding: 0 !important;")
              .d-flex.mt-1.w-100.px-1
                h3.text-primary Rapprochement
              .d-flex.my-0.w-100( style="padding: 0 !important;")
                .d-flex.flex-column.w-100
                  .d-flex.mb-1.w-100.px-1
                    b-form-group.mr-1(label="Fournisseur *")
                      validation-provider(#default='{ errors }' name='providerId' rules="required" mode='lazy')
                        ejs-dropdownlist( id="providerId" popupHeight='300px' :dataSource='providersList' :readonly="readOnly" :fields="{ text: 'name', value: 'id' }" v-model='creditProvider.providerId' @change="checkUniqueProviderAndNumber")
                        small.text-danger {{ errors[0] }}
                    b-form-group(label="Catégorie d'achat")
                      validation-provider(#default='{ errors }' name='purchaseCategoryId')
                        ejs-dropdownlist( id="purchaseCategoryId" popupHeight='300px' :dataSource='purchaseCategoriesTreeList' :readonly="readOnly" :fields="{ text: 'label', value: 'id' }" v-model='creditProvider.purchaseCategoryId')
                        small.text-danger {{ errors[0] }}
                        //- Treeselect.text-left.w-100(v-model="creditProvider.purchaseCategoryId" :disabled="readOnly" :multiple="false" :options="purchaseCategoriesTreeList" value-consists-of="LEAF_PRIORITY" noOptionsText="Aucun résultat trouvé" noChildrenText="Aucun résultat trouvé" :show-count="true" placeholder="" search-nested)
            #repartitions.pt-1.w-100(v-show="tabActive == 'repartitions'" style="height: 100%; overflow-y: auto;")
                  .d-flex.flex-column.w-100.px-1.pt-2
                    .d-flex.justify-content-between.mb-1.w-100
                      span.font-weight-bold.w-100(v-if="!creditProvider.creditProviderRepartitions.length" style="color:orange;align-self:center")
                      span.font-weight-bold.w-100(v-else-if="remainingToBeDistributed > 0" style="color:orange;align-self:center") Il reste {{ formatCurrency(remainingToBeDistributed) }} à répartir
                      span.font-weight-bold.w-100(v-else-if="remainingToBeDistributed < 0" style="color:orange;align-self:center") Il reste {{ formatCurrency(remainingToBeDistributed) }} à répartir. La somme des répartitions excède le montant de votre avoir. Veuillez ajuster.
                      span.font-weight-bold.w-100.text-success(v-else style="align-self:center") L'intégralité de votre avoir est répartie.
                      div
                        ejs-button( v-if="!readOnly" v-on:click.native="addRepartitionRecord()" content="+ Ajouter une répartition" :isPrimary="true" locale='fr' :cssClass="'e-outline e-small'")
                    sync-grid#overviewgrid( ref="overviewgrid" :gridData="creditProvider.creditProviderRepartitions" :name="name" :allowPaging="allowPaging" :headerData="headerData" :editSettings="editSettings" :allowGrouping="allowGrouping"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton"  @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked")
            b-row.my-0.justify-content-center
              b-col.py-0(cols='12')
                .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 1rem;')
                  div
                    b-button(v-if="!readOnly" @click="ignoreCreditProvider" variant='outline-danger')
                      | Ignorer ce document
                  div.d-flex.align-items-center.ml-auto(v-if="creditProvider.creditProviderStatus < 2")
                    b-button-group
                      b-dropdown.dropdown-options(text='Finaliser le document' :variant="remainingToBeDistributed < 0 ? 'dark' : 'success'" dropup :disabled="(remainingToBeDistributed <= 0 && creditProvider.creditProviderRepartitions && creditProvider.creditProviderRepartitions.length == 0) || remainingToBeDistributed < 0 || isDuplicateProviderAndNumber")
                        b-dropdown-item.cursor-pointer(@click="saveCreditProvider(1, true)" :disabled="creditProvider.creditProviderStatus >= 1" :style="creditProvider.creditProviderStatus >= 1  ? 'opacity:0.5' : ''") 
                          span.chip.justify-content-start
                            span.point.bg-warning
                            span.label.text-warning Passer en validation
                        b-dropdown-item.cursor-pointer(@click="saveCreditProvider(2, true)" :disabled="readOnly" :style="readOnly ? 'opacity:0.5' : ''") 
                          span.chip.justify-content-start
                            span.point.bg-primary
                            span.label.text-primary Valider l'avoir fournisseur
                      b-button.btn-credit-builder-header.m-0(@click="saveCreditProvider(creditProvider.creditProviderStatus, false)" :variant="remainingToBeDistributed < 0 ? 'outline-dark' : 'outline-success'" :disabled="remainingToBeDistributed < 0")
                        | Enregistrer
                      b-button.btn-credit-builder-header.bl-white(@click="saveCreditProvider(creditProvider.creditProviderStatus, true)" style="border-left: 1px solid #ccc !important;" :variant="remainingToBeDistributed < 0 ? 'outline-dark' : 'outline-success'" :disabled="remainingToBeDistributed < 0")
                        feather-icon(icon='LogOutIcon')
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import DropdownlistRepartitionProvider from "@/components/purchase/document-repartition-provider/DropdownlistRepartitionProvider.vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  formatNumber,
  formatCurrency,
} from "@/types/api-orisis/library/FormatOperations";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      tvasList: [],
      key: 0,
      tabActive: "details",
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      allowPaging: false,
      allowGrouping: false,
      headerData: [],
      headerLoaded: false,
      name: "creditsRepartitions",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouvelle répartition",
        titleEdit: "Modifier la répartition",
        titleField: "",
      },
      tagguerButton: false,
      selectedRows: [],
      isDuplicateProviderAndNumber: false,
    };
  },
  async created() {
    if (this.$route.params.id) {
      await this.getCreditsProviderById({
        creditProviderId: this.$route.params.id,
        getPaymentProviders: false,
      }).then((res) => {
        if (res.referentielTvas.length == 0) {
          this.addReferencielTva();
        }
      });
    } else {
      this.creditProvider = {
        id: null,
        documentReference: null,
        documentDate: null,
        valueDate: null,
        description: null,
        totalTTC: 0,
        totalHT: 0,
        remainingToPayTtc: 0,
        referentielTvas: [
          {
            referentielTvaId: null,
            value: 0,
            valueTva: 0,
          },
        ],
        creditProviderRepartitions: [
          {
            companyId: null,
            affairId: null,
            priceHt: 0,
            referencielTvaId: null,
            index: 0,
          },
        ],
        providerId: null,
        purchaseCategoryId: null,
      };
    }
    await this.getReferencielTVAs({});
    await this.getCompanies({});
    await this.getAffairs({});
    await this.getPurchaseCategoriesTree({});
    this.setHeaderData();
  },
  computed: {
    ...mapGetters([
      "isLoadingCreditProvider",
      "isUpdatingCreditProvider",
      "isLoadingReferencielTVAsList",
      "referencielTvasInstitutionSettingsList",
      "isLoadingCompaniesList",
      "companiesList",
      "purchaseCategoriesTreeList",
      "affairsList",
      "isLoadingAffairsList",
      "institutionSettingsActive",
    ]),
    referencielTVAsListAvailable() {
      const referentielTvaIdSet = new Set();
      const referentielTvaAvailable = [];

      for (const detail of this.creditProvider.referentielTvas) {
        const referentielTvaId = detail.referentielTvaId;

        if (!referentielTvaIdSet.has(referentielTvaId)) {
          const matchingOption =
            this.institutionSettingsActive.referencielTvas.find(
              (option) => option.referencielTva.id === referentielTvaId
            );

          if (matchingOption) {
            referentielTvaAvailable.push(matchingOption.referencielTva);
            referentielTvaIdSet.add(referentielTvaId);
          }
        }
      }
      return referentielTvaAvailable;
    },
    providersList() {
      return this.companiesList.filter(
        (el) => el.companyTypeId == 4 || el.companyTypeId == 8
      );
    },
    creditProvider: {
      get() {
        return this.$store.getters.creditProvider;
      },
      set(value) {
        return this.$store.commit("SET_CREDIT_PROVIDER", value);
      },
    },
    remainingToBeDistributed() {
      let total = 0;
      if (
        this.creditProvider.creditProviderRepartitions &&
        this.creditProvider.creditProviderRepartitions.length
      ) {
        this.creditProvider.creditProviderRepartitions.forEach((item) => {
          total = Math.round((total + item.priceHt) * 100) / 100;
        });
      }
      return Number(this.creditProvider.totalHT) - total;
    },
    readOnly() {
      return this.creditProvider.creditProviderStatus >= 2;
    },
  },
  methods: {
    formatNumber,
    formatCurrency,
    ...mapActions([
      "getCreditsProviderById",
      "updateCreditsProvider",
      "updateCreditProviderOptions",
      "getReferencielTVAs",
      "getCompanies",
      "getPurchaseCategoriesTree",
      "getCreditsProvidersByProviderIds",
      "getAffairs",
    ]),
    generateTvasList() {
      this.tvasList = [];
      this.creditProvider.referentielTvas.forEach((item) => {
        const availableTvas =
          this.institutionSettingsActive.referencielTvas.map(
            (tva) => tva.referencielTva
          );
        this.tvasList.push(
          availableTvas.filter(
            (elem) =>
              !this.creditProvider.referentielTvas.some(
                (el) => el.referentielTvaId == elem.id
              )
          )
        );
        if (item.referentielTvaId) {
          this.tvasList[this.tvasList.length - 1].push(
            availableTvas.find((el) => el.id == item.referentielTvaId)
          );
        }
      });
    },
    openInNewTab(uri) {
      window.open(uri, "_blank");
    },
    checkUniqueProviderAndNumber() {
      this.$bvToast.hide();
      if (
        this.creditProvider.providerId &&
        this.creditProvider.documentReference
      ) {
        this.getCreditsProvidersByProviderIds({
          providerIds: [this.creditProvider.providerId],
        }).then((res) => {
          const duplicate = res.find(
            (el) =>
              el.documentReference == this.creditProvider.documentReference &&
              el.providerId == this.creditProvider.providerId &&
              el.id !== this.creditProvider.id
          );
          if (duplicate) {
            this.isDuplicateProviderAndNumber = true;
          } else {
            this.isDuplicateProviderAndNumber = false;
          }
        });
      }
    },
    addReferencielTva() {
      this.creditProvider.referentielTvas.push({
        referentielTvaId: null,
        value: 0,
        valueTva: 0,
      });
    },
    changeTab(tab) {
      this.setHeaderData();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.tabActive = tab;
    },
    addRepartitionRecord() {
      this.$refs.overviewgrid.$refs.overviewgrid.endEdit();
      setTimeout(() => {
        this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
      }, 100);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    deleteReferencielTva(item, index) {
      this.creditProvider.referentielTvas.splice(index, 1);
      this.calculateTotals();
    },
    async deleteButtonClicked(args) {
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async actionBegin(args) {
      if (args.requestType == "beginEdit" && this.readOnly) {
        args.cancel = true;
      } else if (args.requestType === "save" && args.action === "add") {
        this.creditProvider.creditProviderRepartitions.push({
          ...args.data,
          index: this.creditProvider.creditProviderRepartitions.length,
        });
      } else if (args.requestType === "save" && args.action == "edit") {
        this.creditProvider.creditProviderRepartitions.splice(
          args.rowIndex,
          1,
          args.data
        );
      } else if (args.requestType == "delete") {
        this.creditProvider.creditProviderRepartitions.findIndex(
          (element, index) => {
            if (
              element.affairId == args.data[0].affairId &&
              element.companyId == args.data[0].companyId &&
              element.referencielTvaId == args.data[0].referencielTvaId
            ) {
              this.creditProvider.creditProviderRepartitions.splice(index, 1);
            }
          }
        );
        this.creditProvider.creditProviderRepartitions.forEach(
          (element, index) => {
            element.index = index;
          }
        );
      }
    },
    setHeaderData() {
      this.headerData = [
        {
          type: "number",
          field: "companyId",
          headerText: "Client",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          textAlign: "Left",
          edit: {
            source: this.companiesList.filter(
              (el) => el.companyTypeId == 5 || el.companyTypeId == 7
            ),
            fields: { text: "name", value: "id" },
            type: "dropdowndependencies",
            name: "dropdowncompany",
            target: "dropdownaffair",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "affairId",
          headerText: "Affaire",
          allowFiltering: false,
          allowSorting: false,
          width: 300,
          minWidth: 300,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          textAlign: "Left",
          edit: {
            source: this.affairsList,
            fields: {
              text: "name",
              value: "id",
              dataSource: this.affairsList,
            },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            origine: "dropdowncompany",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "priceHt",
          headerText: "Montant HT",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          defaultValue: 0,
          textAlign: "Left",
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          format: "formatCurrency",
          validationRules: { required: true },
          width: 150,
          minWidth: 150,
        },
        {
          type: "number",
          field: "referencielTvaId",
          headerText: "TVA",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Left",
          defaultValue: 2,
          edit: {
            source: this.referencielTVAsListAvailable,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
          validationRules: { required: true },
          width: 100,
          minWidth: 100,
        },
      ];
      if (!this.readOnly) {
        this.headerData.push({
          type: "commandsDelete",
          headerText: "Action",
          width: 50,
          minWidth: 50,
        });
      }
      this.headerLoaded = true;
    },
    saveCreditProvider(status, reload) {
      const seenAffairIds = new Array();
      let hasDuplicate = false;
      this.$refs.overviewgrid.$refs.overviewgrid.endEdit();

      for (const element of this.creditProvider.creditProviderRepartitions) {
        let repartition = {
          affairId: element.affairId,
          referencielTvaId: element.referencielTvaId,
        };
        if (elementExiste(seenAffairIds, repartition)) {
          hasDuplicate = true;
        } else {
          seenAffairIds.push(repartition);
        }
      }

      if (hasDuplicate) {
        this.$bvToast.toast(
          "Il n'est pas possible d'associer plusieurs répartitions à la même affaire avec le même taux de TVA.",
          {
            title: `Erreur`,
            variant: "danger",
            solid: true,
          }
        );
      } else {
        this.$refs.formCreditProvider
          .validate()
          .then((success) => {
            if (success) {
              if (
                status == 2 &&
                this.creditProvider.creditProviderRepartitions.length == 0
              ) {
                this.$bvModal
                  .msgBoxConfirm(
                    `Aucune répartion n'a été ajouté. Souhaitez-vous ajouter une repartion avant de valider cette avoir fournisseur ?`,
                    {
                      title:
                        "Êtes-vous sûr de vouloir valider cette avoir fournisseur ?",
                      size: "sm",
                      okVariant: "outline-success",
                      okTitle: "Continuer",
                      cancelTitle: "Répartir",
                      cancelVariant: "warning",
                      hideHeaderClose: true,
                      centered: true,
                    }
                  )
                  .then((value) => {
                    if (value) {
                      this.updateCreditProviderOptions({
                        creditProvider: {
                          ...this.creditProvider,
                          creditProviderStatus: status,
                        },
                        loading: reload,
                      }).then(() => {
                        if (reload)
                          this.$tabs.close({ to: "/credits-provider" });
                      });
                    } else {
                      this.tabActive = "repartitions";
                    }
                  });
              } else {
                this.updateCreditProviderOptions({
                  creditProvider: {
                    ...this.creditProvider,
                    creditProviderStatus: status,
                  },
                  loading: reload,
                }).then(() => {
                  if (reload) this.$tabs.close({ to: "/credits-provider" });
                });
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      function elementExiste(tableau, element) {
        return tableau.some(function (item) {
          return (
            item.affairId === element.affairId &&
            item.referencielTvaId === element.referencielTvaId
          );
        });
      }
    },
    ignoreCreditProvider() {
      this.updateCreditsProvider({
        creditProvider: { ...this.creditProvider, creditProviderStatus: 5 },
        updateStatus: true,
      }).then(() => {
        this.$tabs.close({ to: "/credits-provider" });
      });
    },
    calculateTva(item, index) {
      const tva = this.institutionSettingsActive.referencielTvas.find(
        (el) => el.referencielTva.id === item.referentielTvaId
      );
      if (tva) {
        this.creditProvider.referentielTvas[index].valueTva = this.formatNumber(
          (Number(item.value) * Number(tva.referencielTva.value)) / 100
        );
      } else {
        this.creditProvider.referentielTvas[index].valueTva = 0;
      }
      this.calculateTotals();
    },
    calculateTotals(calculateTva = false) {
      let totalHt = 0;
      let totalTtc = 0;
      this.creditProvider.referentielTvas.forEach((referencielTva, index) => {
        if (calculateTva && referencielTva.referentielTvaId) {
          const tva = this.institutionSettingsActive.referencielTvas.find(
            (el) => el.referencielTva.id === referencielTva.referentielTvaId
          );
          if (tva) {
            referencielTva.valueTva = this.formatNumber(
              (Number(referencielTva.value) *
                Number(tva.referencielTva.value)) /
                100
            );
          } else {
            referencielTva.valueTva = 0;
          }
        }
        totalHt += Number(referencielTva.value);
        totalTtc += this.formatNumber(
          Number(referencielTva.value) + Number(referencielTva.valueTva)
        );
      });
      this.creditProvider.totalHT = totalHt;
      this.creditProvider.totalTTC = totalTtc;
      this.creditProvider.remainingToPayTtc = totalTtc;
    },
    cancel() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  components: {
    BButton,
    ValidationObserver,
    ValidationProvider,
    quillEditor,
    vSelect,
    BFormGroup,
    BFormInput,
    DatePicker,
    Treeselect,
    DropdownlistRepartitionProvider,
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  width: 100%;
}
.sticky-element {
  position: sticky;
  background-color: #fff;
  z-index: 1000;
  &-top {
    top: 0;
  }
  &-bottom {
    bottom: 1px;
  }
}
.container-trash {
  margin-top: 2rem;
}
</style>
